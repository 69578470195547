import "./index.css";

// components
import Navbar from "./navbar";
import Content from "./content";
import Footer from "./footer";
import {Helmet} from 'react-helmet'

function App() {
  return (
    <div className="app">
      <Helmet>
        <title>Hello | @ragilyudhanto</title>
        <meta name="description" content="Ini adalah website Ragil Manggalaning Y." property="og:description" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ragilyudhanto" />
        <meta name="twitter:creator" content="@ragilyudhanto" />
        <meta property="og:url" content="https://ragilyudhanto.xyz/" />
        <meta property="og:description" content="Ini adalah website Ragil Manggalaning Y." />
        <meta property="og:title" content="Hello | @ragilyudhanto" />
      </Helmet>
      {/* navbar */}
      <Navbar />
      {/* content */}
      <Content />

      {/* footer */}
      <Footer />
    </div>
  );
};

export default App;
