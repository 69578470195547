import React from 'react';
import button from './button.png';
import Typing from 'react-typing-animation';

const Content = () => {
    return (
        <div className="content" >
            <div className="desc">
                <img
                    style={{
                        marginBottom :'10px'
                    }}
                    src={button} />
                <Typing cursorClassName="text-white" speed={20}>
                <p style={{
                    color : '#B2B1B9'
                }} className="text-comment"># hy! salam kenal, saya <span className="text-blue">Ragil Manggalaning Yudhanto</span>, seorang Software Engineer. Saya suka hal baru, belajar sesuatu yg seru dan berbagi ilmu.</p>
                <Typing.Delay ms={1000} />
                <p className="text-white">blog <span className="text-red">=</span> <a href="https://blog.ragilyudhanto.xyz" className="text-blue">“blog.ragilyudhanto.xyz"</a></p>
                <Typing.Delay ms={800} />
                <p className="text-white">portfolio <span className="text-red">=</span> <a href="https://dev.ragilyudhanto.xyz" className="text-blue">“dev.ragilyudhanto.xyz"</a></p>
                <Typing.Delay ms={800} />
                <p className="text-white">experiments <span className="text-red">=</span> <a href="https://lab.ragilyudhanto.xyz" className="text-blue">“lab.ragilyudhanto.xyz"</a>
                <Typing.Cursor /> 
                </p>
                <span className="text-dark">.</span>
                </Typing>
            </div>
        </div>
    )
}

export default Content