import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <a href="mailto:ragilmanggalaning42@gmail.com?subject=Hello!" target="_blank">/email</a>
            <a href="https://github.com/ragil000" target="_blank">/github</a>
            <a href="https://www.instagram.com/ragilyudhanto/" target="_blank">/instagram</a>
            <a href="https://twitter.com/ragilyudhanto" target="_blank">/twitter</a>
            <a href="https://www.facebook.com/RagilManggalaningYudhanto" target="_blank">/facebook</a>
        </div>
    )
}

export default Footer